import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import './assets/jobDetails.css';
import { Link } from 'react-router-dom';

const JobDetailsPage = () => {
    const { objectID } = useParams();
    const [jobDetails, setJobDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const algoliaClient = algoliasearch('LPP9NQN6KV', '08833b668130c09b56224ed3ebe59522');
    const index = algoliaClient.initIndex('jobs');

    const fetchJobDetails = useCallback(async () => {
        setLoading(true);
        try {
            const { content } = await index.getObject(objectID);
            setJobDetails(content);
            setError(null); // Clear any previous errors on successful fetch
        } catch (error) {
            console.error('Error fetching job details:', error);
            setError('Failed to fetch job details');
            setLoading(false);
        }
    }, [objectID, index]);

    useEffect(() => {
        if (objectID) { // Check if objectID is present
            fetchJobDetails(); // Fetch job details when component mounts
        } else {
            setError('No job ID provided');
            setLoading(false);
        }
    }, [objectID, fetchJobDetails]); // Dependency array now correctly includes fetchJobDetails

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div className='job-details-container'>
                <div className='error-message'>Error: {error}</div>
                <div><strong>Failed to fetch details for job ID: </strong> {objectID || 'undefined'}</div><br/>
                <div><Link to="/" className="jobDetails-home-button">Return to Home Page</Link></div>
            </div>
        )
    }

    if (!jobDetails) {
        return (
            <div className='job-details-container'>
                <div>No job details found for jobId: {objectID || 'undefined'}</div>
            </div>
        );
    }

    return (
        <div className="job-details-container">
            <h2>{jobDetails.title}</h2>
            <p>{jobDetails.company}</p>
            <p>{jobDetails.location}</p>
            <p>{jobDetails.description}</p>
            <p>Salary Range: {jobDetails.salary}</p>
            {/* Additional job details as needed */}
            <Link to="/" className="jobDetails-home-button">Return to Home Page</Link>
        </div>
    );
};

export default JobDetailsPage;
