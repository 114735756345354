import React from 'react';
import "./App.css";
import { 
  Routes,
  Route, } from "react-router-dom";

import ComingSoon from './components/ComingSoon';
import DemoApp from './components/demo/DemoApp';
import About from './components/About';
import JobDetailsPage from './components/demo/JobDetailsPage';

function App () {

    return (
      <>
      <div className="card">
        <Routes>
          <Route exact path="/" element={<ComingSoon/>} />
          <Route path="/demo" element={<DemoApp/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/job/:jobId" element={<JobDetailsPage/>} /> {/* Define route for JobDetails */}
        </Routes>
      </div>
      </>
    );
  }

export default App;