import React from 'react';
import logo from '../assets/logo/ecobuddy-white-emblem.svg';
import { Link } from 'react-router-dom';
import "../App.css";
import { PopupButton } from '@typeform/embed-react'

export const ComingSoon = () => {

    return (
      <>
        <div className="header">
          <div className="logo">
            <a href="."> <img src={logo} className="logo-image" alt="ecobuddy-logo"/> ECOBUDDY.io  </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
          <h1>Coming Soon</h1>
            <br/>
            <p>An app to help you discover your next job opportunity.<br/>
            <br/>
              <h2>✨ Your insights matter to us ✨</h2><br/>
              Please share your thoughts by filling out this brief survey and sign up for waitlist. Thank you!
              </p>
          </div>
          <div className="cta-holder">
            {/* <a href="https://emily103.typeform.com/to/JxvUdJWJ" className='cta' target='blank'>
              Complete Survey
            </a> */}
            <PopupButton id="JxvUdJWJ" className="cta">
              Complete Survey
            </PopupButton>
            <Link to="/demo" className="cta">
              Experience Demo
            </Link>
            </div>
            <div className='contact-info'>
              <a href="mailto:hello@boldmotive.org">
              If you're curious to know more, <strong>click here to shoot us an email.</strong></a><br/>
            </div>
        </div>
        <div className="footer">
          <span>Powered by 
            <a className="underlined" href="https://www.linkedin.com/in/boldmotive" target="_blank" rel="noopener noreferrer"> Bold Motive Group, LLC. </a> 
          </span>
        </div>
      </>
    );
  }

export default ComingSoon;