import React from 'react';

const About = () => {
    return (
        <div>
            <h1>About Page</h1>
            <p>Welcome to our website! We are dedicated to providing you with the best job hunting solutions.</p>
        </div>
    );
};

export default About;