import React from 'react';
import "../../App.css";
import { Link } from 'react-router-dom';
import logo from '../../assets/logo/ecobuddy-white-emblem.svg';
import SearchBar from './SearchBar';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';

function DemoApp () {
    const searchClient = algoliasearch('LPP9NQN6KV', 'b5798de0398b6d64f7b6d217615827fd');

    return (
      <>
      <div className="app-container">
        <div className="header">
          <div className="logo">
            <a href="."> <img src={logo} className="logo-image" alt="ecobuddy-logo"/> ECOBUDDY.io  </a>
          </div>
          <div className="nav">
            <Link to="/signup" className="signup-button">Sign Up</Link>
            <Link to="/login" className="login-button">Login</Link>
          </div>
        </div>
        <div className="demo-header">
            <h1>Welcome to the demo!</h1>
        </div>
        <div className="demo-content">
            <InstantSearch 
                searchClient={searchClient} 
                indexName="jobs"
                insights={true}
                >
                {/* Widgets */}
                <SearchBar />
            </InstantSearch>
        </div>
        </div>
      </>
    );
  }

export default DemoApp;