import React, { useState, useRef } from 'react';
import './assets/searchbar.css';
// import { FaSearchengin } from 'react-icons/fa'
import algoliasearch from 'algoliasearch/lite'; // Import Algolia Lite Client
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const SearchBar = () => {
    const history = useNavigate(); // Initialize useHistory hook
    const inputRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    
    // Algolia setup
    const algoliaClient = algoliasearch('LPP9NQN6KV', '08833b668130c09b56224ed3ebe59522');
    const index = algoliaClient.initIndex('jobs'); // Initialize index 'jobs'

    const handleSearchChange = async (e) => {
        const query = e.target.value;
        setSearchTerm(query);

        if (query === "") { // Clear search results if input is empty
            setSearchResults([]);
        } else { // Filter results based on input
            try {
                const { hits } = await index.search(query);
                setSearchResults(hits.map(hit => ({
                    objectID: hit.objectID,
                    title: hit.title || '',  // Assuming 'title' is a field in 'jobs' entity
                    description: hit.description || '',  // Assuming 'description' is a field in 'jobs' entity
                    location: hit.location || '',
                    company: hit.company || '',
                    salary: hit.salary || '',
                    // Add more fields as needed
                })));
            } catch (error) {
                console.error('Error searching Algolia:', error);
                setSearchResults([]); // Clear results on error
            }
        }
    };

    const handleSearchSubmit = (event) => {
        if (event.key === 'Enter') {
            console.log('Searching for:', searchTerm);
            // Trigger your search logic here
            if (inputRef.current) {
                inputRef.current.blur(); // Blur the input if it exists
            }
        } else if (event.key === 'Escape') { // Handle escape key
            setSearchTerm('');
            setSearchResults([]);
            setIsExpanded(false); // Optionally collapse the search bar
            if (inputRef.current) {
                inputRef.current.blur(); // Remove focus from the input
            }
        }
    };

    const handleFocus = () => { // Expands the search box when clicked
        setIsExpanded(true);
    };

    const handleBlur = () => {
        if (!searchTerm) {  // Contracts the box only if there is no text
            setIsExpanded(false);
        }
    };

    const clearSearch = () => { // Clear search term and results
        setSearchTerm('');
        setSearchResults([]);
        inputRef.current.focus(); // Keep focus on input after clearing
    };

    const openDetails = (objectID) => {
        // Implement logic to open details, e.g., navigate to a new page or show modal
        // console.log('Open details for:', url);
        history(`/job/${objectID}`); // Redirect to job details page
    };

    function formatSalary(salary) {
        return salary.toLocaleString(); // This will format the number with commas by default in most locales.
    }      

    return (
        <div className="searchbar-container">
        <div className="box" onClick={() => inputRef.current && inputRef.current.focus()}>
            <div className="search-header">
                Step 1. {" "}
                Search for a job title {" "}
            </div>
            <input
                ref={inputRef}
                type="text"
                name="search"
                placeholder="" // do not adjust
                className="input"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleSearchSubmit}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={{ 
                    width: isExpanded ? '100%' : '0%',  // Dynamically adjust width based on state
                    color: isExpanded ? '#4c4c4c' : '#FFFFFF',  // Dynamic text color
                    borderColor: isExpanded ? '#ffd52d' : '#FFFFFF'  // Dynamic border color
                }}
            />
            {/* NOTE: add this functinality to multiple card scenario upon user's search query */}
            {isExpanded && searchTerm && ( // Display clear button only if search term is present
                <button onClick={clearSearch} className="clear-button">
                    X
                </button>
            )}     
            <div className="results-content">
                {searchResults.length > 0 ? (
                    <div className='results-container'> {/* Updated class name */}
                        {searchResults.map((result) => (
                            <div key={result.objectID} className="search-card">
                                <h3>{result.title}</h3>
                                <p>{result.description}</p>
                                <p>{result.location}</p>
                                <p>{result.company}</p>
                                <p>Salary: {'$' + formatSalary(result.salary)}</p>
                                <div className="details-button-container">
                                    <button className="details-button" onClick={() => openDetails(result.objectID)}>
                                        Open Details
                                    </button>
                                </div>
                            </div>  
                        ))}
                    </div>
                ) : (
                    searchTerm && (
                        <div className='no-results-message'>Oops! No results found. Try again.</div>
                    )
                )}  
                </div>
            </div>
        </div>
    );
};

export default SearchBar;